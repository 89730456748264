<template>
  <div class="onlineEdit">
    <div v-if="!successful">
      <p class="tips">卡思优派是一家专业的猎头公司，之前和您联系过~</p>
      <el-form
        @submit.native.prevent
        :model="from"
        ref="from"
        :rules="rules"
        label-width="110px"
        class="addForm"
      >
        <p class="tit">基本信息</p>
        <el-form-item label="姓名：" prop="name">
          <el-input
            v-model.trim="from.name"
            style="width: 90%"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model.trim="from.mobile" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item
          label="出生年月："
          prop="birth"
          style="padding-bottom: 17px"
        >
          <el-date-picker
            v-model="from.birth"
            type="month"
            placeholder="选择年月"
            value-format="yyyy-MM-dd"
            style="width: 90%"
          ></el-date-picker>
        </el-form-item>
        <p class="tit">求职意向</p>
        <el-form-item label="求职状态：" prop="jobStatus">
          <el-select
            placeholder="请选择"
            v-model="from.jobStatus"
            size="mini"
            style="width: 90%"
          >
            <el-option label="在职看机会" value="在职看机会"></el-option>
            <el-option label="在职不看机会" value="在职不看机会"></el-option>
            <el-option label="离职" value="离职"></el-option>
            <el-option label="应届毕业生" value="应届毕业生"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="期望城市：" prop="forwardLocations">
          <el-cascader
            filterable
            size="mini"
            :show-all-levels="false"
            :options="ColumnCityList"
            v-model="from.forwardLocations"
            placeholder="请选择"
            :props="props1"
            collapse-tags
            style="width: 90%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="期望职位：" prop="title">
          <el-input
            v-model.trim="from.title"
            style="width: 90%"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="期望月薪：" style="padding-bottom: 17px">
          <el-form-item
            label-width="0"
            prop="lowAimSalary"
            style="
              width: 42%;
              display: inline-block;
              margin-bottom: 0;
              padding: 0;
            "
          >
            <el-input
              class="appendInput"
              v-model.number.trim="from.lowAimSalary"
              :disabled="from.MianYi"
              maxlength="10"
            >
            </el-input>
          </el-form-item>
          <span
            style="
              display: inline-block;
              height: 35px;
              line-height: 35px;
              width: 6%;
              text-align: center;
            "
          >
            -
          </span>
          <el-form-item
            label-width="0"
            prop="highAimSalary"
            style="
              width: 42%;
              display: inline-block;
              margin-bottom: 0;
              padding: 0;
            "
          >
            <el-input
              class="appendInput"
              v-model.number.trim="from.highAimSalary"
              :disabled="from.MianYi"
              maxlength="10"
            >
            </el-input>
          </el-form-item>
          <div>
            <el-checkbox v-model="from.MianYi" style="width: 12%"
              >面议</el-checkbox
            >
          </div>
        </el-form-item>
        <p class="tit">工作经历</p>
        <el-form-item label="当前公司：" prop="currentCompany">
          <el-input
            v-model.trim="from.currentCompany"
            style="width: 90%"
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item label="当前职位：" prop="currentTitle">
          <el-input
            v-model.trim="from.currentTitle"
            style="width: 90%"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="在职时间：" style="padding-bottom: 17px">
          <el-form-item
            label-width="0"
            style="
              width: 42%;
              display: inline-block;
              margin-bottom: 0;
              padding: 0;
            "
          >
            <el-date-picker
              v-model="from.empStartDate"
              type="month"
              value-format="yyyy-MM"
              placeholder="请选择"
              :picker-options="employmentsStartTime"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <span
            style="
              display: inline-block;
              height: 35px;
              line-height: 35px;
              width: 6%;
              text-align: center;
            "
          >
            -
          </span>
          <el-form-item
            label-width="0"
            style="
              width: 42%;
              display: inline-block;
              margin-bottom: 0;
              padding: 0;
            "
          >
            <el-date-picker
              :disabled="from.empSoFar"
              v-model="from.empEndDate"
              type="month"
              value-format="yyyy-MM"
              placeholder="请选择"
              :picker-options="employmentsEndTime"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <div>
            <el-checkbox v-model="from.empSoFar" style="width: 4%"
              >至今</el-checkbox
            >
          </div>
        </el-form-item>
        <p class="tit">教育经历</p>
        <el-form-item label="学历：" prop="education">
          <el-select
            placeholder="请选择"
            v-model="from.education"
            size="mini"
            style="width: 90%"
          >
            <el-option label="初中" value="初中"></el-option>
            <el-option label="高中" value="高中"></el-option>
            <el-option label="中技" value="中技"></el-option>
            <el-option label="中专" value="中专"></el-option>
            <el-option label="大专" value="大专"></el-option>
            <el-option label="本科" value="本科"></el-option>
            <el-option label="硕士" value="硕士"></el-option>
            <el-option label="博士" value="博士"></el-option>
            <el-option label="博士后" value="博士后"></el-option>
            <el-option label="MBA" value="MBA"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校名称：" prop="school">
          <el-input
            v-model.trim="from.school"
            style="width: 90%"
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="专业名称："
          prop="major"
          style="padding-bottom: 17px"
        >
          <el-input
            v-model.trim="from.major"
            style="width: 90%"
            maxlength="50"
          ></el-input>
        </el-form-item>
      </el-form>
      <p class="ps">我们承诺严格保密您的简历信息，请您放心！</p>
      <div class="btn">
        <el-button type="primary" :loading="saveLoading" @click="save"
          >提 交</el-button
        >
      </div>
    </div>
    <div v-if="successful" class="successMsg">
      <i class="iconfont el-icon-success icon"></i>
      <p>简历保存成功</p>
      <span>感谢您的信任与支持</span>
    </div>
  </div>
</template>

<script>
import {
  candidateOperateResumeDetail, //简历详情
  candidateOperateUpdateResume, //更新简历
  candidateOperateCityTree, //城市列表
} from "../../api/html";
export default {
  data() {
    const validateTel = (rule, value, callback) => {
      let reg = /^[^A-Za-z\u4E00-\u9FA5]{8,}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      from: {
        name: "",
        mobile: "",
        birth: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateTel, trigger: "blur" },
        ],
      },
      // 日期选择器时间限制
      employmentsStartTime: {
        disabledDate: (time) => {
          let endDateVal = this.from.endDate;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      employmentsEndTime: {
        disabledDate: (time) => {
          let beginDateVal = this.createdEmployments.startDate;
          if (beginDateVal) {
            return (
              time.getTime() < new Date(beginDateVal).getTime() ||
              time.getTime() > Date.now()
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      successful: false,
      saveLoading: false,
      ColumnCityList: [], // 城市列表
      props1: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        // disabled: "hasChild",
        checkStrictly: true,
      },
    };
  },
  created() {
    this.getData();
    this.loadColumnCityList();
  },
  methods: {
    loadColumnCityList(type) {
      //城市列表
      this.ColumnCityList = [];
      candidateOperateCityTree().then((res) => {
        if (res.success) {
          this.ColumnCityList = res.result;
        }
      });
    },
    getData() {
      candidateOperateResumeDetail({
        rid: this.$route.query.rid,
      }).then((res) => {
        if (res.success) {
          //   console.log(res);
          this.from = res.result;
        }
      });
    },
    save() {
      this.saveLoading = true;
      return new Promise((resolve, reject) => {
        if ((this.from.forwardLocations || []).length > 5) {
          this.$message.error("期望城市不能超过5个！");
          this.saveLoading = false;
          reject();
          return false;
        }
        this.$refs["from"].validate((valid) => {
          if (valid) {
            candidateOperateUpdateResume(this.from).then((res) => {
              if (res.success) {
                this.successful = true;
                //   console.log(res);
                // this.from = res.result;
              }
            });
          } else {
            // 如果失败 验证不成功
            this.saveLoading = false;
            reject();
          }
        });
        this.saveLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.onlineEdit {
  font-size: 14px;
  max-width: 750px;
  margin: 0 auto;
  .tips {
    text-align: center;
    padding: 10px;
    background: #fff8f5;
    color: #ed6120;
  }
  .addForm {
    .tit {
      padding: 10px;
      color: #838383;
    }
    .el-form-item {
      background: #fff;
      margin: 0 0;
      padding: 0.5rem 0 0;
      padding-top: 17px;
      //   border-bottom: 1px #ececec solid;
    }
    // .el-input,:deep(.el-input__inner){
    //     border:0!important
    // }
  }
  .ps {
    color: #999;
    text-align: center;
    padding: 15px 0 30px;
  }
  .btn {
    text-align: center;
    background: #fff;
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // width: 100%;
    // z-index: 9;
    .el-button {
      width: calc(100% - 20px);
      margin: 10px;
      height: 40px;
      font-size: 16px;
    }
  }
  .successMsg {
    padding-top: 120px;
    text-align: center;
    background: #fff;
    height: 100vh;
    .icon {
      font-size: 80px;
      color: #44c966;
    }
    p {
      color: #333333;
      font-size: 24px;
      font-weight: 500;
      margin-top: 20px;
    }
    span {
      display: block;
      position: absolute;
      bottom: 10%;
      color: #999;
      font-size: 14px;
      left: 50%;
      margin-left: -54px;
    }
  }
}
</style>

<style lang="scss">
// .empData{
//     .el-date-editor{
//         width:90%
//     }
// }
.onlineEdit {
  // .el-form-item__content,.el-input__inner,.el-form-item__label{
  //   line-height: 50px!important;
  // }
  .el-form-item__label {
    font-size: 16px;
    color: #333;
  }
}
</style>
