// 统一请求路径前缀在libs/axios.js中修改
import {
    getHtmlRequest,//html请求
    postHtmlRequest,//html请求
} from './http';

//职位邀请反馈
export const candidateOperateInviteFeedback = (params) => {
    return postHtmlRequest('/candidate/operate/invite-feedback', params)
}

// 简历详情
export const candidateOperateResumeDetail = (params) => {
    return getHtmlRequest('/candidate/operate/resume-detail', params)
}

// 城市选项树
export const candidateOperateCityTree = (params) => {
    return getHtmlRequest('/candidate/operate/city-tree', params)
}

// 更新简历
export const candidateOperateUpdateResume = (params) => {
    return postHtmlRequest('/candidate/operate/update-resume', params)
}

// 上传简历文件
export const candidateOperateUploadFile = (params) => {
    return postHtmlRequest('/candidate/operate/upload-file', params)
}

// 上传更新简历
export const candidateOperateUploadResume = (params) => {
    return postHtmlRequest('/candidate/operate/upload-resume', params)
}
